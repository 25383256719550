import React, { useContext, useEffect } from 'react'
import LocaleContext from 'context/LocaleProvider'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import ArticleLayout from 'components/PagesLayout/Article'

const Article = props => {
  const lang = useContext(LocaleContext)

  useEffect(() => {
    lang?.setPageDetails({
      uid: props?.pageContext?.alternativeLanguageUid,
      type: 'article',
    })
  }, [])

  return <ArticleLayout {...props} />
}

export default withPreview(Article)

export const articleQuery = graphql`
  query article($uid: String!, $locale: String!) {
    prismicArticle(uid: { eq: $uid }, lang: { eq: $locale }) {
      lang
      uid
      data {
        date(formatString: "MMM. DD, YYYY")
        page_type
        tags {
          text
        }
        title {
          text
        }
        excerpt {
          text
        }
        main_image {
          alt
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                name {
                  text
                }
                position {
                  text
                }
              }
            }
          }
        }
        content {
          html
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        open_graph_title {
          text
        }
        open_graph_description {
          text
        }
        open_graph_image {
          alt
          localFile {
            url
          }
        }
        breadcrumb_title {
          text
        }
        focus_phrase {
          text
        }
      }
    }
    allPrismicArticle(
      filter: { lang: { eq: $locale } }
      sort: { fields: data___date }
    ) {
      edges {
        node {
          lang
          uid
          data {
            date(formatString: "MMM. DD, YYYY")
            page_type
            tags {
              text
            }
            title {
              text
            }
            excerpt {
              text
            }
            content {
              html
            }
            main_image {
              alt
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicArticleGlobalContent(lang: { eq: $locale }) {
      data {
        share {
          text
        }
        back_to {
          text
        }
        estimated_read_time {
          text
        }
        by {
          text
        }
      }
    }
  }
`
