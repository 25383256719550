import React, { useContext, useEffect, useState } from 'react'
import {
  ArticleHeroBannerImage,
  Sidebar,
  ArticleContent,
  ArticleContainer,
  BackTo,
  BackToLink,
  AuthorContent,
  AuthorName,
  AuthorPosition,
  SocialMediaSharing,
  ShareText,
} from './styles'
import Container from 'components/Container'
import Wrapper from 'components/Wrapper'
import IntersectionVisible from 'react-intersection-visible'
import GlobalContext from 'context/GlobalStateProvider'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import Icon from 'components/Icon'
import BlogSnippet from 'components/BlogSnippet'
import FeaturedArticle from 'components/FeaturedArticle'

const ArticleLayout = props => {
  const {
    data: {
      prismicArticle: {
        uid,
        data: {
          date,
          author: {
            document: {
              data: { name, position },
            },
          },
          content,
          excerpt,
          main_image: {
            alt: image_alt,
            localFile: {
              childImageSharp: { fluid: image_fluid },
            },
          },
          tags,
          title,
        },
      },
      prismicArticleGlobalContent: {
        data: { share, back_to, estimated_read_time, by },
      },
      allPrismicArticle,
    },
  } = props
  const globalContext = useContext(GlobalContext)
  const [isVisible, setIsVisible] = useState(false)
  const [relatedArticles, setRelatedArticles] = useState([])

  useEffect(() => {
    setRelatedArticles(
      allPrismicArticle?.edges
        ?.filter(({ node }) => {
          const articleTags = tags?.text.includes(',')
            ? tags?.text?.split(',')
            : [tags?.text]

          const nodeTags = node?.data?.tags?.text?.includes(',')
            ? node?.data?.tags.text.split(',')
            : node?.data?.tags.text

          return uid !== node.uid && articleTags.find(t => nodeTags.includes(t))
        })
        .slice(0, 2)
    )
    globalContext.setLightColorTheme(false)
  }, [])

  const observerProps = {
    onShow: () => setIsVisible(true),
    onHide: () => setIsVisible(false),
    options: {
      rootMargin: '-80px 0px -80px 0px',
    },
  }

  return (
    <>
      <Wrapper
        style={{
          background: '#ffffff',
          top: globalContext?.openMenu ? '152px' : 0,
        }}
      >
        <Container>
          <FeaturedArticle
            date={date}
            estimated_read_time={estimated_read_time}
            node={{
              data: {
                tags,
                title,
                excerpt,
                content,
              },
            }}
          />
        </Container>
        <ArticleHeroBannerImage
          fluid={{ ...image_fluid, aspectRatio: 1680 / 764 }}
          alt={image_alt}
        />
        <Container style={{ position: 'relative' }}>
          <ArticleContainer>
            {back_to?.text ? (
              <BackTo>
                <Icon type="back-arrow" className="back-arrow" />
                <BackToLink pageHandle={'/blog'} pageType={'page'}>
                  {back_to?.text}
                </BackToLink>
              </BackTo>
            ) : (
              <></>
            )}
            <IntersectionVisible {...observerProps}>
              <Sidebar isVisible={isVisible}>
                <div>
                  <AuthorContent>
                    {name?.text ? (
                      <AuthorName type="tertiary-small">{`${by?.text.toLowerCase()} ${
                        name?.text
                      }, `}</AuthorName>
                    ) : (
                      <></>
                    )}
                    {position?.text ? (
                      <AuthorPosition type="tertiary-small">
                        {position?.text}, psb
                      </AuthorPosition>
                    ) : (
                      <></>
                    )}
                  </AuthorContent>
                  <SocialMediaSharing>
                    {share?.text ? (
                      <ShareText type="tertiary-small">{share.text}</ShareText>
                    ) : (
                      <></>
                    )}
                    <div>
                      <FacebookShareButton url={props.location.href}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="38"
                          height="37"
                          viewBox="0 0 38 37"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g fill="#AD4700">
                              <g>
                                <path
                                  d="M102.36 12.308h-1.827c-1.433 0-1.71.649-1.71 1.6v2.097h3.417l-.445 3.286h-2.973v8.429H95.26v-8.43H92.28v-3.285h2.98v-2.422c0-2.813 1.803-4.343 4.437-4.343 1.262 0 2.346.089 2.663.13v2.938zM96.9 0C86.462 0 78 8.273 78 18.48c0 10.206 8.462 18.48 18.9 18.48 10.439 0 18.9-8.274 18.9-18.48C115.8 8.273 107.339 0 96.9 0z"
                                  transform="translate(-263 -1746) translate(185 1746)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </FacebookShareButton>
                      <TwitterShareButton url={props.location.href}>
                        <Icon type="twitter" />
                      </TwitterShareButton>
                      <LinkedinShareButton url={props.location.href}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="37"
                          height="37"
                          viewBox="0 0 37 37"
                        >
                          <g fill="none" fillRule="evenodd">
                            <g fill="#AD4700">
                              <g>
                                <path
                                  d="M27.72 27.72h-3.771v-5.979c0-1.426-.025-3.26-1.96-3.26-1.962 0-2.261 1.554-2.261 3.159v6.08H15.96V15.425h3.615v1.682h.052c.503-.967 1.734-1.987 3.569-1.987 3.819 0 4.524 2.546 4.524 5.857v6.743zM11.342 13.44c-1.164 0-2.102-.941-2.102-2.1 0-1.159.938-2.1 2.102-2.1 1.158 0 2.098.941 2.098 2.1 0 1.159-.94 2.1-2.098 2.1zm2.098 14.28h-4.2v-12.6h4.2v12.6zM18.48 0C8.273 0 0 8.273 0 18.48c0 10.206 8.273 18.48 18.48 18.48s18.48-8.274 18.48-18.48C36.96 8.273 28.687 0 18.48 0z"
                                  transform="translate(-185 -1746) translate(185 1746)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </LinkedinShareButton>
                    </div>
                  </SocialMediaSharing>
                </div>
              </Sidebar>
            </IntersectionVisible>
            <ArticleContent
              dangerouslySetInnerHTML={{ __html: content.html }}
            />
          </ArticleContainer>
        </Container>
        <BlogSnippet
          query={{ back_to, estimated_read_time }}
          articles={relatedArticles}
        />
      </Wrapper>
    </>
  )
}

export default ArticleLayout
